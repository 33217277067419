import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { map, Observable, switchMap, combineLatest } from 'rxjs';

import { LanguagesService } from '@ppg/core/language';

import { MarketBrandProviderService } from './market-brand-provider.service';
import { UserInfoService } from './user-info.service';
import { Func } from '../../shared/models/function-type';
import { Market } from '../../shared/models/market.model';
import { MarketLabelProviderService } from '../../shared/services/market-label-provider.service';
import {
  Configuration,
  ConfigurationQueryVariables,
  DeliveryStoreSelectionAllowed,
  PaymentSettings,
  PricingSettings,
  GlobalSettings,
  RecaptchaSettings,
  ShowDataSheets,
  ShowStoreOrders,
  PaymentSettingsConfiguration,
  PricingSettingsConfiguration,
  AccountNumberRules,
} from '../models/configuration';
import { CanShowStoreOrdersGQL } from '../queries/can-show-store-orders.query';
import { DeliveryStoreSelectionAllowedGQL } from '../queries/delivery-store-selection-allowed.query';
import { GlobalSettingsAnonymousGQL } from '../queries/global-settings-anonymous.query';
import { GlobalSettingsGQL } from '../queries/global-settings.query';
import { PaymentSettingsGQL } from '../queries/payment-settings.query';
import { PricingSettingsGQL } from '../queries/pricing-settings.query';
import { ShowDataSheetsGQL } from '../queries/show-data-sheets.query';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private marketBrandProviderService: MarketBrandProviderService,
    private userInfoService: UserInfoService,
    private languagesService: LanguagesService,
    private canShowStoreOrdersGQL: CanShowStoreOrdersGQL,
    private canChangeFulfillmentStoreGQL: DeliveryStoreSelectionAllowedGQL,
    private globalSettingsGQL: GlobalSettingsGQL,
    private globalSettingsAnonymousGQL: GlobalSettingsAnonymousGQL,
    private pricingSettingsGQL: PricingSettingsGQL,
    private showDataSheetsGQL: ShowDataSheetsGQL,
    private marketLabelProviderService: MarketLabelProviderService,
    private paymentSettingsGQL: PaymentSettingsGQL,
  ) {}

  getConfigurationStatus<T>(gqlQuery: Query<Configuration<T>, ConfigurationQueryVariables>): Observable<T> {
    return this.getUserMarket().pipe(
      switchMap((market) =>
        gqlQuery
          .fetch({ marketKey: market.key }, { fetchPolicy: 'network-only' })
          .pipe(map((response) => response.data.configuration)),
      ),
    );
  }

  canShowStoreOrders(): Observable<boolean> {
    return this.getConfigurationStatus<ShowStoreOrders>(this.canShowStoreOrdersGQL).pipe(
      map((c) => c.orderSettings.showStoreOrders),
    );
  }

  showDataSheets(): Observable<boolean> {
    return this.getConfigurationStatus<ShowDataSheets>(this.showDataSheetsGQL).pipe(
      map((c) => c.productSettings.showDataSheets),
    );
  }

  getPricingSettings(): Observable<PricingSettings> {
    return this.getConfigurationStatus<PricingSettingsConfiguration>(this.pricingSettingsGQL).pipe(
      map((c) => c.pricingSettings),
    );
  }

  ecoFeeEnabled(): Observable<boolean> {
    return this.getPricingSettings().pipe(map((c) => c.ecoFeeEnabled));
  }

  recaptchaSettings(): Observable<RecaptchaSettings> {
    return this.getGlobalSettingsAsAnonymous().pipe(map((settings) => settings.recaptchaSettings));
  }

  deliveryStoreSelectionAllowed(): Observable<boolean> {
    return this.getConfigurationStatus<DeliveryStoreSelectionAllowed>(this.canChangeFulfillmentStoreGQL).pipe(
      map((c) => c.cartSettings.deliveryStoreSelectionAllowed),
    );
  }

  getGlobalSettings(): Observable<GlobalSettings> {
    return this.userInfoService.user() ? this.getGlobalSettingsForUser() : this.getGlobalSettingsAsAnonymous();
  }

  private getGlobalSettingsAsAnonymous(): Observable<GlobalSettings> {
    const { market } = this.marketLabelProviderService;

    return this.languagesService.language$.pipe(
      switchMap((language) => {
        return this.globalSettingsAnonymousGQL
          .fetch(
            { marketKey: market.key, locale: language },
            { fetchPolicy: 'cache-first', context: { clientName: 'anonymous' } },
          )
          .pipe(map((response) => response.data.configuration.globalSettings));
      }),
    );
  }

  private getGlobalSettingsForUser(): Observable<GlobalSettings> {
    return combineLatest({ market: this.getUserMarket(), language: this.languagesService.language$ }).pipe(
      switchMap(({ market, language }) =>
        this.globalSettingsGQL.fetch({ marketKey: market.key, locale: language }, { fetchPolicy: 'cache-first' }),
      ),
      map((response) => response.data.configuration.globalSettings),
    );
  }

  // dedicated ContactUs url for CDP, not related to Mixed Navigation functionality
  getContactUsUrl(): Observable<string> {
    return this.getGlobalSettings().pipe(map((settings) => settings.contactUsUrl));
  }

  getEAccountUrls(): Observable<GlobalSettings> {
    return this.getGlobalSettings().pipe(map((settings) => ({ ...settings, contactUsUrl: undefined })));
  }

  getFeatureFlagStatus(featureFlagSelector: Func<boolean, GlobalSettings, number>): Observable<boolean> {
    return this.getGlobalSettingsAsAnonymous().pipe(map(featureFlagSelector));
  }

  getCanadaFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.canadaEnabled));
  }

  getShowInventoryFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.showInventory));
  }

  getShowFavoritesFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.showFavorites));
  }

  getUserCustomerReassignmentFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.userCustomerReassignmentEnabled));
  }

  getRequestToOrderProductNotInCatalogFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(
      map((globalSettings) => globalSettings.requestToOrderProductNotInCatalogEnabled),
    );
  }

  getPromotionsFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.promotionsEnabled));
  }

  getCouponsFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.couponsEnabled));
  }

  getAddAccountEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.addAccountEnabled));
  }

  getOneJobPerCustomerEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.oneJobPerCustomerEnabled));
  }

  getReorderProductsNotInCatalogEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.reorderProductsNotInCatalogEnabled));
  }

  getCreditCardsEnabledFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.creditCardsEnabled));
  }

  getFormStackReturnsEnabledFeatureFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.orderReturns.formStackReturnsEnabled));
  }

  getUserToMultipleCustomersEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.userToMultipleCustomersEnabled));
  }

  getStorePrivilegesWhenPrimaryEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.storePrivilegesWhenPrimaryEnabled));
  }

  getCashCustomerEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(
      map((globalSettings) => globalSettings.b2BSection?.enabled && globalSettings.b2BSection?.cashCustomerRegEnabled),
    );
  }

  getB2CCustomerEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.b2CSection?.enabled));
  }

  getReorderPreviousOrderEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.reorderPreviousOrderEnabled));
  }

  getShowOrderStatusEnabledFlag(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.orderStatusEnabled));
  }

  getPmcAccountsEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.pmcAccountsEnabled));
  }

  getPmcSelfRegistrationEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.pmcSelfRegistrationEnabled));
  }

  getUserProfileEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.userProfileEnabled));
  }

  getUserMarket(): Observable<Market> {
    const countryCode$ = this.userInfoService.getUserInfo().pipe(map((x) => x.countryCode));
    return countryCode$.pipe(map((countryCode) => this.marketBrandProviderService.getMarket(countryCode)));
  }

  getSmartBannerEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.smartBannerEnabled));
  }

  getAllOrderHistoryNewFlowEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.allOrderHistoryNewFlowEnabled));
  }

  getOnlineOrderInvoiceEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.viewOnlineOrderInvoiceEnabled));
  }

  getInStoreOrderInvoiceEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.viewInStoreOrderInvoiceEnabled));
  }

  getOptionalCustomerAddressEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(
      map((globalSettings) => {
        return globalSettings.optionalCustomerAddressEnabled;
      }),
    );
  }

  getNotRestrictJobSalesRepToCountryEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(
      map((globalSettings) => globalSettings.notRestrictJobSalesRepToCountryEnabled),
    );
  }

  getJobCreationCountryPreselectedEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.jobCreationCountryPreselectedEnabled));
  }

  getPuertoRicoCardPaymentsEnabled(): Observable<boolean> {
    return this.getGlobalSettings().pipe(map((globalSettings) => globalSettings.puertoRicoCardPaymentsEnabled));
  }

  getPaymentSettings(): Observable<PaymentSettings> {
    return this.getConfigurationStatus<PaymentSettingsConfiguration>(this.paymentSettingsGQL).pipe(
      map((c) => c.paymentSettings),
    );
  }

  getAccountNumberValidationRules(): Observable<AccountNumberRules> {
    return this.getGlobalSettings().pipe(
      map((globalSettings) => globalSettings.globalGroupSettings?.validationSettings?.accountNumberRules),
    );
  }
}
